import { loadComponent } from '@/helpers/asyncLoaders'
import store from '@/store/store'

import {
  VUEX_USER_HAS_CONFERENCE_ASIAN,
  VUEX_USER_HAS_CONFERENCE_DOMESTIC,
  VUEX_USER_HAS_CONFERENCE_INTERNATIONAL
} from '@/store/constants/user'

const hasPerms = () => {
  return store.getters[VUEX_USER_HAS_CONFERENCE_ASIAN] ||
    store.getters[VUEX_USER_HAS_CONFERENCE_DOMESTIC] ||
    store.getters[VUEX_USER_HAS_CONFERENCE_INTERNATIONAL]
}

export const routes = {
  path: 'fall24',
  name: 'fall24--main',
  meta: {
    conferenceClass: 'fall24'
  },
  component: loadComponent('GlobalConference/Fall24/Main.vue'),
  redirect: { name: 'fall24--categories' },
  children: [
    {
      path: '',
      name: 'fall24--dashboard',
      component: loadComponent('GlobalConference/Fall24/Dashboard.vue'),
      beforeEnter: (to, from, next) => {
        if (hasPerms()) {
          next()
        } else {
          next('/')
        }
      },
      children: [
        {
          path: '',
          name: 'fall24--dashboard-default',
          redirect: { name: 'fall24--categories' }
        },
        {
          path: 'categories',
          name: 'fall24--categories',
          component: loadComponent('GlobalConference/Fall24/Pages/Categories.vue'),
          meta: {
            eventCategory: 'fall24',
            title: 'Product Categories',
            navigation: [
              'Womens',
              'Mens',
              'Kids',
              'Unisex'
            ]
          }
        },
        {
          path: 'ads',
          name: 'fall24--ads',
          component: loadComponent('GlobalConference/Fall24/Pages/Advertising.vue'),
          meta: {
            eventCategory: 'fall24',
            title: 'Advertising',
            navigation: [
              'Womens',
              'Mens',
              'Kids',
              'Unisex'
            ]
          }
        }
      ]
    }
  ]
}
