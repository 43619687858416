import spring25 from './spring25'

const state = {
  currentSite: 'spring25',
  previousSite: 'fall24'
}

const modules = {
  spring25
}

export default {
  state,
  modules,
  namespaced: true
}
